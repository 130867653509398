






import { Component, Vue } from "vue-property-decorator";
import taxonomyWrapper from "@/components/knockoutWrappers/TaxonomyWrapper.vue";

@Component({
  components: {
    taxonomyWrapper,
  },
})
export default class Taxonomy extends Vue {}
