




import { Component, Vue } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/taxonomy/taxonomy";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import TaxonomyModule from "@/store/clients/Taxonomy.module";

const Taxa = getModule(TaxonomyModule, store);

@Component
export default class TaxonomyWrapper extends Vue {
  async mounted(): Promise<void> {
    const taxonomy = await Taxa.getTaxonomyLogVariables();
    const param = {
      taxonomy: taxonomy,
    };

    const instance = new koHtmlAndViewModel.viewModel(param);
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;

    ko.applyBindings(instance, this.$el);
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
  }
}
